.details-container {
  // min-width: 14.5rem;
  flex: 2;
  // background-color: rgba(169, 221, 211, 0.41);
  background-color: white;
  box-shadow: 0 0 0.5rem -0.2rem black;
  // max-width: 100%;
  // width: calc(100% - 2rem);
  padding: 1rem;
  margin: 0.5rem;
  cursor: pointer;
  color: black;
  min-height: 8rem;
  height: fit-content;
  transition: all 1s;
  border-radius: 0.5rem;

  & > .heading {
    width: 100%;
    text-align: center;
    margin: 0.5rem auto;
    text-transform: uppercase;
    height: 1.5rem;
    font-size: large;
    font-weight: bold;
  }
  & > .content {
    min-height: 5rem;
    max-height: 10rem;
    display: flex;
    // flex-wrap: wrap;
    overflow: hidden auto;
    justify-content: space-around;
    & > .unit-and-title {
      margin: 0.5rem;
      font-size: large;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    & > .unit-and-title > .unit-value {
      font-size: large;
      margin: 0.5rem;
    }
    & > .unit-and-title > .unit-title {
      font-size: medium;
      text-transform: capitalize;
      margin: 0.5rem;
    }
  }
}
