:root {
  font-size: 16px;
  font-family: "Open Sans";
  --text-primary: #b6b6b6;
  --text-secondary: #ececec;
  --bg-primary: #23232e;
  --blue-theme: #13d6f8;
  --bg-secondary: #141418;
  --transition-speed: 600ms;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body::-webkit-scrollbar, body *::-webkit-scrollbar {
  width: 0.25rem;
  height: 0.25rem;
}

body::-webkit-scrollbar-track , body *::-webkit-scrollbar-track{
  background: #1e1e24;
}

body::-webkit-scrollbar-thumb , body *::-webkit-scrollbar-thumb{
  background: #6649b8;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
