.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: #000000e1;
  display: flex;
  align-items: center;
  justify-content: center;
  @media only screen and (max-width: 640px) {
    height: 100vh;
  }
  z-index: 100;
}
