.navbar {
  position: fixed;
  background-color: var(--bg-primary);
  transition: 200ms ease width;
  z-index: 10;
  img {
    max-width: 100%;
    max-height: 100%;
    // border-radius: 5px;
  }
  .logo > .nav-link {
    justify-content: center;
    transition: all var(--transition-speed) ease-in-out 0.5s;
    filter: none !important;
    background-color: transparent;
  }
}

.navbar-nav {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}

@media only screen and (min-width: 640px) {
  .navbar {
    top: 0;
    width: 6rem;
    height: 100vh;
    &:hover .logo {
      width: 10rem;
      height: 10rem;
    }
    &:hover {
      .link-text {
        display: inline;
        opacity: 1;
        transition: opacity var(--transition-speed);
      }
      width: 16rem;
    }
  }
}

@media only screen and (max-width: 640px) {
  .navbar {
    bottom: 0;
    width: 100vw;
    min-height: 5rem;
    height: -webkit-fit-content;
  }

  .navbar-nav {
    flex-direction: row;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
