.chart-container {
  position: relative;
  aspect-ratio: 4/3;
  background-color: var(--bg-primary);
  height: 70vh;
  width: calc(100% - 5rem);
  box-shadow: 0.2rem 0.2rem 0.6rem -0.2rem #555;
  border-radius: 0.3rem;
  -webkit-border-radius: 0.3rem;
  -moz-border-radius: 0.3rem;
  -ms-border-radius: 0.3rem;
  -o-border-radius: 0.3rem;
  //ds
  @media only screen and (max-width: 768px) {
    height: auto;
    width: 100%;
  }

  .chart-container-title {
    margin: 0.5rem;
    color: white;
    text-transform: capitalize;
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .chart {
    width: 100%;
    height: 85%;
    background-color: white;
    margin: 0.5rem auto;
  }

  .reduce-space {
    width: 6rem;
  }

  .status-circle {
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    margin-right: 1rem;
  }
}
.make-content-center {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .top-row {
    background-color: var(--bg-primary);
    padding: 0.5rem 0;
    margin-bottom: 1rem;
    display: flex;
    gap: 0.5rem;
    justify-content: space-evenly;
    width: 100%;
    flex-wrap: wrap;
    align-items: center;
  }
  .export-to-csv-btn {
    background-color: rgb(230, 77, 77);
    max-width: 12rem;
    margin: 10px;
    padding: 0.8rem 2rem;
    text-align: center;
    text-transform: uppercase;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;
    border-radius: 10px;
    display: block;

    & a {
      color: white;
    }

    &:hover {
      background-position: right center; /* change the direction of the change here */
      color: #fff;
      text-decoration: none;
    }
  }
}
