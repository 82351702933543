@media only screen and (max-width:425px){
  .main{
   margin-bottom: 6rem;
  }
}
@media only screen and (min-width: 640px) {
  .main {
    margin-left: 6rem;
    // margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: hidden;
  }
}
