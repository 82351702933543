* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.container {
  min-height: 100vh;
  background-color: var(--primary-black);
  color: var(--blue-theme);
}

.logo-container {
  width: 12rem;
  margin: 1rem auto;
}
.logo-container > img {
  border-radius: 0.5rem;
  width: inherit;
}
.login-component {
  margin: 0 auto;
  padding: 1rem;
  width: clamp(500px, 50vw, 500px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--primary-white);
  box-shadow: 0.1rem 0.1rem 1rem 0rem #000;
  background-color: var(--bg-primary);
}
.login-component > * {
  user-select: none;
}
.login-component > h3 {
  font-size: large;
  margin: 1rem;
  margin-top: 0.2rem;
}
.login-greetings {
  font-weight: 300;
  font-family: monospace;
  font-size: small;
  color: var(--primary-white);
  letter-spacing: 0.1rem;
}

.login-forgot-password {
  text-decoration: none;
  margin: 0 1rem;
  margin-top: 0.2rem;
  color: var(--blue-theme);
  font-weight: lighter;
  font-size: small;
  letter-spacing: 0.1rem;
}

.login-btn {
  background-color: var(--blue-theme);
  color: var(--bg-secondary);
  width: 90%;
  border: none;
  padding: 0.5rem;
  margin: 1rem 0;
  border-radius: 0.2rem;
  letter-spacing: 0.1rem;
  cursor: pointer;
  &:hover {
    background-color: #44a3ff;
  }
}
input[type="password"]::-ms-reveal,
input[type="password"]::-ms-clear {
  display: none;
}

.small-btn {
  width: 200px !important;
  background-color: #dfa220;
}

@media only screen and (max-width: 640px) {
  .container {
    height: 90vh;
  }

  .login-component {
    width: 100%;
    height: 90%;
  }
}

@media only screen and (min-width: 640px) {
  .container {
    display: flex;
    align-items: center;
    border-radius: 1rem;
    justify-content: center;
  }

  .login-component {
    position: relative;
    min-height: 60vh;
    height: fit-content;
    border-radius: 1rem;
  }

  .login-component > h3 {
    font-size: x-large;
    margin-top: 0.5rem;
    margin-bottom: 2rem;
  }
  .login--label {
    font-size: medium;
  }

  .login-btn {
    width: 90%;
    margin: 2rem 0;
  }
}
