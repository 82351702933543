.nav-item {
  width: 100%;
}
.logout {
  margin-top: auto;
}

.logo {
  height: 100px;
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 1rem 0;
}

.nav-link {
  display: flex;
  align-items: center;
  min-height: 5rem;
  color: var(--text-primary);
  text-decoration: none;
  margin: 0.25rem 0;
  filter: grayscale(100%) opacity(0.7);
  transition: var(--transition-speed);

  svg {
    min-height: 2rem;
    min-width: 3rem;
    margin: 0 1.5rem;
    color: var(--blue-theme);
    @media only screen and (min-width: 425px) {
      min-width: 2rem;
    }
  }

  &:hover {
    filter: grayscale(0%) opacity(1);
    background: var(--bg-secondary);
  }
}

.active {
  filter: grayscale(0%) opacity(1);
  background: var(--bg-secondary);
}

.link-text {
  opacity: 0;
  color: var(--blue-theme);
  margin-left: 1rem;
}

@media only screen and (max-width: 600px) {
  .logo {
    display: none;
  }

  .link-text {
    display: none;
  }

  .nav-link {
    justify-content: center;
  }
}
