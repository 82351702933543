.analysis-table-holder {
  border: 0.1rem double black;
  border-radius: 0.3rem;
  position: relative;
  padding: 0.3rem;
  max-width: calc(100% - 6rem);
  overflow-x: auto;
  // height: 70%;
  margin: 2rem;

  .analysis-table {
    margin-bottom: 0.3rem;
    thead,
    tbody {
      position: relative;
    }

    td {
      // border: 0.1rem solid;

      background-color: lightgoldenrodyellow;
      color: black;
      border-radius: 0.3rem;
      -webkit-border-radius: 0.3rem;
      -moz-border-radius: 0.3rem;
      -ms-border-radius: 0.3rem;
      -o-border-radius: 0.3rem;
    }

    th {
      padding: 0.5rem;
      background-color: aliceblue;
      color: var(--blue-theme);
    }

    td,
    th {
      text-align: center;

      &:nth-child(1) {
        position: sticky;
        left: -0.3rem;
        background-color: aliceblue;
        border: none;
        color: var(--blue-theme);
      }
    }
  }
}

.pagination-btns {
  display: flex;
  gap: 0.3rem;
  overflow-x: auto;
  width: 20rem;
  justify-content: center;
  margin-bottom: 2rem;

  li {
    list-style: none;
    background-color: aliceblue;
    border-radius: 0.3rem;
    -webkit-border-radius: 0.3rem;
    -moz-border-radius: 0.3rem;
    -ms-border-radius: 0.3rem;
    -o-border-radius: 0.3rem;

    div {
      padding: 0.5rem;
      cursor: pointer;
      color: var(--blue-theme);
    }
  }
}
