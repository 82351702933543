.pie-chart {
  position: relative;
  display: flex;
  margin: 1rem auto;
  justify-content: center;
  .circular-progress-circle {
    position: relative;
    width: 400px;
    height: 400px;
  }

  .pie-title {
    text-transform: capitalize;
  }
}
.recharts-layer.recharts-pie-sector {
  cursor: pointer;
}
.worker-view {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  width: 100%;
}
#worker-attendence-heading {
  margin: 0.5rem auto;
  width: fit-content;
  color: #555;
}
