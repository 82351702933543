.worker-details-holder {
  border: 0.1rem solid #181c24;
  border-radius: 0.3rem;
  padding: 0.5rem;
  overflow-y: scroll;
  margin: 2rem auto;
  max-width: 25rem;
  aspect-ratio: 2/2;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  .list-item {
    cursor: pointer;
    padding: 0.5rem;
    margin-bottom: 1rem;
    width: 100%;
    // background-color: #2e3138;
    background-color: #555;
    border-radius: 0.3rem;
    display: flex;
    align-items: center;
    box-shadow: 0.2rem 0.2rem 0.3rem -0.1rem #888;
  }
  .list-item .avatar {
    width: 20%;
  }
  .list-item .avatar img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    box-shadow: 0 0 0.3rem -0.1rem white;
    object-fit: cover;
    background-color: black;
    outline: none;
  }
  .list-item .user-details {
    width: 80%;
    margin-left: 5%;
    color: white;
    padding: 0 0.3rem;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    .username {
      font-weight: bold;
    }
    .occupation {
      font-size: small;
      font-style: italic;
    }
  }
}
