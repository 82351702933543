.drop-down-menu {
  position: relative;
  min-width: 7rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0.3rem 0.5rem;
  border: 0.1rem solid var(--bg-primary);
  color: var(--bg-primary);
  border-radius: 0.3rem;
  -webkit-border-radius: 0.3rem;
  -moz-border-radius: 0.3rem;
  -ms-border-radius: 0.3rem;
  -o-border-radius: 0.3rem;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
  > .drop-down-search {
    width: 100%;
    height: 100%;
    background-color: transparent;
    border: none;
    padding: 0.3rem 0rem;
    outline: none;
    color: var(--bg-primary);
    &:hover,
    &:focus,
    &:active {
      color: inherit;
      border: none;
    }
  }
  > p {
    display: flex;
    align-items: center;
    gap: 0.3rem;
  }
  &:hover {
    background-color: var(--bg-primary);
    color: #fff;
    .drop-down-list {
      height: fit-content;
      border: 1px solid #000;
    }
  }
  .freeze {
    height: fit-content !important;
    border: 1px solid #000 !important;
  }
  .drop-down-list {
    position: absolute;
    height: 0;
    min-width: 7rem;
    border: none;
    top: 100%;
    left: 0;
    box-shadow: 0rem 0rem 0.3rem -0.1rem #fff;
    background-color: #fff;
    color: #000;
    border-radius: 0.2rem;
    overflow: hidden;
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -o-transition: all 0.5s;
    &:hover {
      height: fit-content;
    }
    & > li {
      list-style: none;
      padding: 0.3rem 1rem;
      transition: all 0.5s;
      -webkit-transition: all 0.5s;
      -moz-transition: all 0.5s;
      -ms-transition: all 0.5s;
      -o-transition: all 0.5s;
      &:hover {
        color: #fff;
        background-color: var(--bg-primary);
      }
    }
  }
}
.drop-down-menu.inverted {
  border: 0.1rem solid var(--blue-theme) !important;
  color: var(--blue-theme) !important;
  position: relative;
  z-index: 2;
  .drop-down-list {
    background-color: var(--bg-primary) !important;
    color: #fff;
  }
}
