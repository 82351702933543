.details-showcase {
  flex: 1;
  margin: 1rem;
  padding: 0.1rem;
  min-width: 17rem;
  max-width: 22rem;
  overflow: hidden;
  border-radius: 0.3rem;
  -webkit-border-radius: 0.3rem;
  -moz-border-radius: 0.3rem;
  -ms-border-radius: 0.3rem;
  -o-border-radius: 0.3rem;
  .details-showcase-header,
  .inner-content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 1rem;
    li {
      list-style: none;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 80%;
      margin: 0.3rem auto;
      text-transform: capitalize;
      text-align: center;
      span:nth-child(1) {
        flex: 3;
      }
      span:nth-child(2) {
        flex: 1;
      }
      span:nth-child(3) {
        flex: 2;
      }
    }
  }
  .details-showcase-header {
    height: 30%;
    max-height: 3rem;
    text-transform: uppercase;
    font-weight: bold;
    color: white;
    border-radius: 0.3rem;
    -webkit-border-radius: 0.3rem;
    -moz-border-radius: 0.3rem;
    -ms-border-radius: 0.3rem;
    -o-border-radius: 0.3rem;
  }
  .inner-content {
    height: 70%;
    color: black;
  }
}
@media screen and (max-width: 640px) {
  .vcb {
    min-height: 15rem;
    width: 100%;
  }
}
@media screen and (max-width: 360px) {
  .vcb {
    min-height: 22rem;
    width: 100%;
  }
}
