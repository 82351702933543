.user-profile {
  position: relative;
  max-width: 20rem;
  max-height: 38rem;
  padding: 1rem 0;
  background-color: #eee;
  overflow: hidden;
  border-radius: 1rem;
  box-shadow: 0.1rem 0.1rem 0.5rem -0.2rem #000;
  -webkit-border-radius: 1rem;
  -moz-border-radius: 1rem;
  -ms-border-radius: 1rem;
  -o-border-radius: 1rem;

  @media Only screen and (min-width: 640px) {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    max-width: 40rem;
  }

  > .back-arrow-holder {
    position: absolute;
    top: 0;
    right: 0;
    width: fit-content;
    height: fit-content;
    padding: 1rem;
    cursor: pointer;
  }

  > .back-arrow-holder > .back-arrow {
    width: 2rem;
    height: 2rem;
    border-radius: 30%;
  }
}

.split-for-user-profile {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  @media Only screen and (min-width: 640px) {
    height: 100%;
    width: 30%;
    justify-content: space-evenly;
  }
}

.user-title {
  margin: 0.5rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  > h3 {
    text-transform: capitalize;
    color: #9032f1;
  }
  > p {
    margin-top: 0.3rem;
    text-align: center;
    letter-spacing: 0.2rem;
    text-transform: capitalize;
    font-weight: 500;
    color: #eb24eb;
  }
}
.img-container {
  width: 8rem;
  height: 8rem;
  margin: 0.2rem auto;
  overflow: hidden;
  border-radius: 50%;
  display: flex;
  border: 0.2rem solid #9032f1;
  box-shadow: 0.1rem 0.3rem 0.8rem -0.5rem #000;
  justify-content: center;
  align-items: center;
  > img {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }
}

.user-details {
  height: calc(100% - 12rem);
  width: 100%;
  padding: 0.5rem 1rem;
  overflow: hidden;

  @media Only screen and (min-width: 640px) {
    width: 70%;
    height: 100%;
  }
  .title-with-value-card {
    > h4 {
      text-transform: capitalize;
      color: #9032f1;
    }

    > p {
      padding: 0.5rem;
      border-radius: 0.2rem;
      margin: 0.5rem 0;
    }
  }
}
