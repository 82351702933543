.dashboard-container {
  background-color: var(--primary-black);
  color: var(--blue-theme);
  display: flex;
  width: calc(100% - 2rem);
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 1rem;
  overflow: hidden auto;

  >.right-top {
    margin-top: 1rem;
  }

  .sensitive-data {
    color: black;
    padding: .3rem;
    background-color: white;
    border: 1px solid black;
    border-radius: .3rem;
    cursor: pointer;
    user-select: none;
    height: fit-content;
    width: fit-content;
  }
}

.ArrayTable-and-showcase-holder {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  padding: 2rem;
}

.site-inv-dropdowns {

  .drop-down-menu,
  .drop-down-list {
    width: 12rem;
  }
}

@media only screen and (max-width: 640px) {
  .ArrayTable-and-showcase-holder {
    flex-direction: column;
    padding: 0.5rem;

    &>* {
      flex: none !important;
      margin: 1rem 0;
    }
  }

  .current-and-live {
    width: 95%;
    display: flex;
    flex-direction: column;
    align-items: center;

    &>* {
      flex: 2 !important;
    }
  }

  .site-inv-dropdowns {
    right: unset !important;
    flex-direction: column;
    align-items: center;

    .timer-label {
      width: 80%;
      flex: none !important;
      gap: 3rem !important;
      margin: auto !important;
      justify-content: center !important;
    }
  }
}

@media only screen and (min-width: 640px) {
  .current-and-live {
    display: flex;
    padding: 1rem;
    flex-wrap: wrap;
    width: 100% !important;
    align-items: center;
    justify-content: center;

    &>* {
      width: 25%;
      padding: 0.5rem 0;
      min-height: 10rem;
      min-width: 16rem;
      // max-width: 20rem;
      // aspect-ratio: 1/1;
    }

    &:nth-child(1) {
      width: 50%;
    }
  }
}

.site-inv-dropdowns {
  position: relative;
  width: 100%;
  margin-top: 1rem;
  top: 0;
  display: flex;
  right: 1rem;
  justify-content: flex-end;
  gap: 2rem;

  .timer-label {
    flex: 3;
    display: flex;
    gap: 5rem;
    margin-left: 2rem;
    justify-content: flex-start;
    // position: absolute;
    // left: 5rem;
    // display: flex;
    // gap: 1rem;
    // align-items: center;

    span {
      background-color: var(--bg-primary);
      padding: 0.5rem;
      border-radius: 0.3rem;
      -webkit-border-radius: 0.3rem;
      -moz-border-radius: 0.3rem;
      -ms-border-radius: 0.3rem;
      -o-border-radius: 0.3rem;
    }
  }
}

@media only screen and (max-width: 640px) {
  .dashboard-container {
    margin-bottom: 10vh;
    width: 100%;
  }
}