// .modal {
//   position: fixed;
//   z-index: 2;
//   backdrop-filter: blur(0.1rem);
//   width: 100vw;
//   height: 100vh;
//   background-color: #1115;
//   display: flex;
//   align-items: center;
//   justify-content: center;
// }

.spinner {
  padding: 3vw;
  transform-origin: center center;
  border: 0.2vw solid #1d7ae5;
  border-radius: 50%;
  border-bottom-color: #fff;
  animation: spinner_animation 2s infinite 0s linear;
}

@keyframes spinner_animation {
  from {
    transform: rotateZ(0deg);
    -webkit-transform: rotateZ(0deg);
    -moz-transform: rotateZ(0deg);
    -ms-transform: rotateZ(0deg);
    -o-transform: rotateZ(0deg);
  }
  to {
    transform: rotateZ(360deg);
    -webkit-transform: rotateZ(360deg);
    -moz-transform: rotateZ(360deg);
    -ms-transform: rotateZ(360deg);
    -o-transform: rotateZ(360deg);
  }
}
