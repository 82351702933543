.analytics-container {
  width: 100%;
  gap: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  .date-picker {
    color: var(--blue-theme) !important;
    background-color: var(--bg-primary);
    padding: 0.5rem 1rem;
    border-radius: 5%;

    label {
      display: block;
      margin-bottom: 0.5rem;
    }

    input {
      // border-radius: 10%;
      background-color: var(--bg-primary);
      padding: 0.2rem;
      color: #fff !important;
      border: 1px solid #fff;
    }
  }
}

@media only screen and (max-width: 1120px) {
  .analytics-container {
    width: 100%;
  }
}
