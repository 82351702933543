.login--label {
  font-size: small;
  font-weight: 600;
}
.login-input {
  width: 100%;
  font-weight: lighter;
  padding: 0.3rem;
  color: #000;
  margin: 0.5rem 0;
  outline: none;
}
.valid {
  border: 2px solid #2fb32f;
}

.invalid {
  border: 2px solid #ac3c3c;
}

.login-wrapper {
  position: relative;
  font-weight: bold;
  width: 90%;
  margin: 0.5rem 1rem;
}

.show-password {
  position: absolute;
  top: 50%;
  right: 0;
  margin: 0 0.5rem;
  color: var(--bg-primary);
  cursor: pointer;
  padding: 0.15rem;
}
