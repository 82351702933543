.array-junction-box-container {
  // width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  > h3 {
    font-size: large;
    color: #555;
    margin: 0.2rem;
    font-weight: bold;
  }
}
.array-table {
  width: 95%;
  margin: 1rem 0 0 0;
  overflow: hidden;
  max-width: 60rem;
  background-color: #333;
  box-shadow: 0 0 1rem -0.5rem #111;
  border-radius: 0.5rem;
  -webkit-border-radius: 0.5rem;
  -moz-border-radius: 0.5rem;
  -ms-border-radius: 0.5rem;
  -o-border-radius: 0.5rem;
  thead {
    overflow: hidden;
    background-color: #333;
    cursor: pointer;
    th {
      padding: 0.5rem;
    }
  }
  tbody {
    background-color: #555;
    cursor: pointer;
    td {
      color: #fff;
    }
  }
  > thead th {
    padding: 0.5rem;
  }
  tr {
    > td {
      text-align: center;
      padding: 0.3rem;
      width: 33%;
      cursor: context-menu;
      border: 2px solid #666;
      word-break: break-all;
      transition: padding 0.5s;
    }
  }
  tr.strings-table-container {
    .animate-data {
      overflow: hidden;
      transition: max-height 0.5s ease-in;
    }

    .strings-table {
      width: 100%;
      thead {
        color: var(--blue-theme);
      }
      tr {
        display: flex;
        justify-content: space-around;
        td {
          border: 2px dashed #666;
        }
      }
    }
  }

  tr.strings-table-container[data-active="active"] {
    .animate-data {
      max-height: 640px;
    }
  }
  tr.strings-table-container[data-active="inactive"] {
    td {
      padding: 0;
      border: none;
    }
    .animate-data {
      max-height: 0px;
    }
  }
}
.tool-tip-btn,
.tool-tip-info {
  cursor: pointer;
}
.tool-tip-btn:hover ~ .tool-tip-info,
.tool-tip-info:hover {
  display: block;
}
.tool-tip-info {
  display: none;
  position: absolute;
  left: 2rem;
  bottom: -0.5rem;
  background-color: #222;
  padding: 0.2rem 0.5rem;
  width: 8rem;
  z-index: 10;
  border-radius: 0.5rem;
  box-shadow: 0 0 0.5rem -0.1rem #eee;
}
